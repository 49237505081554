<template>
  <v-card>
    <v-container>
      <v-row dense>
        <v-col cols="12" class="pa-0 text-right">
          <v-switch
            label="Only show those that abandoned in cart"
            v-model="abandoned"
            :disabled="leads.length === 0"
            color="success"
          ></v-switch>
          <v-btn color="color3" text icon small @click.stop="getLeads">
            <v-icon>fas fa-redo</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense v-if="loading">
        <v-col class="text-center" cols="12" >
          <h2>Loading</h2>
          <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row dense v-else-if="!leads.length">
        <v-col cols="12" class="title text-center">
          We have no leads to report :-(
        </v-col>
      </v-row>
      <v-row dense v-else>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredLeads"
            :options.sync="page"
            dense
            show-expand
          >
            <template v-slot:[`item.dtModified`]="{item}">
              {{item.dtModified | unixToLocalDt}}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row dense>
                  <v-col  cols="12" sm="6" md="4" lg="3">
                    <v-card>
                      <v-card-title>
                        <h4>Details</h4>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>Entered On:</v-list-item-content>
                          <v-list-item-content class="align-end">{{item.dtModified | unixToLocalDt}} </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>Entered By:</v-list-item-content>
                          <v-list-item-content class="align-end">{{item.createdBy || 'NA'}} </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>Steps:</v-list-item-content>
                          <v-list-item-content class="align-end">{{item.stepHistory | formatArray}} </v-list-item-content>
                        </v-list-item>

                      </v-list>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" v-for="(player, i) in item.players" :key="`${item.id}_${i}`">
                    <v-card>
                      <v-card-title>
                        <h4>Player {{ i + 1 }}</h4>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>

                        <v-list-item v-for="(field, i) in player.fields" :key="i">
                          <v-list-item-content>{{ field.label }}:</v-list-item-content>
                          <v-list-item-content class="align-end">{{ field.value }}</v-list-item-content>
                        </v-list-item>

                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['tournament', 'active'],
  data () {
    return {
      loading: false,
      search: null,
      leads: [],
      expand: [],
      page: { itemsPerPage: 25 },
      headers: [
        { text: 'Division', value: 'division' },
        { text: 'Team', value: 'teamName' },
        { text: 'Entered On', value: 'dtModified' },
        { text: 'Entered By', value: 'createdBy' }
      ],
      abandoned: true
    }
  },
  computed: {
    filteredLeads () {
      return this.abandoned ? this.leads.filter(f => f.stepHistory.includes('add_to_cart')) : this.leads
    }
  },
  methods: {
    getLeads () {
      this.loading = true
      this.$VBL.tournament.getLeads(this.tournament.id)
        .then(response => {
          this.leads = response.data
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) this.getLeads()
    }
  },
  mounted () {
    this.getLeads()
  }
}
</script>

<style>

</style>
